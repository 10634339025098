import axios from 'axios'
import CONFIG from '../config'
import Token from './auth'
import {Message} from 'element-ui'

const { baseURL, tokenKey } = CONFIG
const api = axios.create({
  baseURL
})
api.interceptors.request.use(config => {
  const token = Token.getToken()
  if (token) {
    config.headers[tokenKey] = token
  }
  return config
})
api.interceptors.response.use(res => {
  return res
}, (err) => {
  if (err.response.status === 400) {
    Message({ type: 'error', message: err.response.data.msg })
  }
})
export default api
