const state = {
  recordTime:[],
}
const mutations = {
  CHANGE_RECORDTIME(state,recordTime){
    state.recordTime = recordTime
  }
}
export default {
  namespaced:true,
  state,
  mutations
}
