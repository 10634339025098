import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
// import ElementUI from 'element-ui';
// 按需导入element-ui
import {Button, Radio, Progress, Form, Input, FormItem,Loading, Message,Dialog} from 'element-ui'
import lang from 'element-ui/lib/locale/lang/en'
import locale from 'element-ui/lib/locale'
// 设置语言
locale.use(lang)
import 'element-ui/lib/theme-chalk/index.css'
import './router/permission'
// 按需导入element-ui
// Vue.use(ElementUI);
Vue.component(Button.name, Button)
Vue.component(Radio.name, Radio)
Vue.component(Progress.name, Progress)
Vue.component(Form.name, Form)
Vue.component(Input.name, Input)
Vue.component(FormItem.name, FormItem)
Vue.component(Dialog.name, Dialog)
Vue.use(Loading)
Vue.config.productionTip = false
Vue.prototype.$message = Message
new Vue({
  router,
  store,
  render: h => h(App)
}).$mount('#app')
