<template>
  <div class="questContainer">
    <div class="questionnaireTitle">在线测评</div>
    <div class="container finishedPage" :class="{'finishedPageToggle':toggleToFinished}">
      <div class="finishedContentContainer">
        <div class="finishedInnerContaienr">
          <div class="finishedTitle">
            终于答完啦！
          </div>
          <div class="finishedContent">
            <div>祝贺你完成了本次测试！</div>
            <div>请耐心等待面试结果，</div>
            <div>预祝好运！</div>
          </div>
          <img class="handshakeImg" src="../assets/handshake.png" alt="握手">
        </div>

      </div>
      <!--      <img class="fpImg" src="../assets/fp.png" alt="">-->
      <!-- @click="getResult" -->
      <div class="btnNext" @click="backToHome">知道了</div>
    </div>
    <div class="container answerPage"  :class="{'questionToggle':toggleToFinished}">
      <div>
        <div >
          <div class="questionContainer" v-loading="loading">
            <div class="question">{{question}}</div>
            <div class="progress">{{progress()}}</div>
          </div>
          <div class="btnGroup">
            <template v-for="(item,index) in btnOption">
              <div class="btnOption" @click="handleNextQuestion(item.id)" @touchstart>
                <!-- <i class="btnIcon iconfont icon-gougou"></i> -->
                {{item.title}}
              </div>
            </template>
          </div>
        </div>
        <div class="footer">
          <timer class="timer" ref="timer" @timeOut="handleTimeOut()"></timer>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
  import {
    btnBgColor,
    customColors
  } from '../utils/enum'
  import Timer from '../components/timer'
  import questionnaireApi from '../api/questionnaire-api'
  import Token from '../utils/auth'
  // import {Message} from 'element-ui'
  export default {
    name: 'Questionnaire',
    components: {
      Timer
    },
    mounted() {
      // 解决ios safari active伪类不生效
      document.body.addEventListener('touchstart',function(){},false);
      this.getQuestionnaireInfo()
    },
    data() {
      return {
        loading: false,
        currentIndex: 0,
        btnBgColor,
        option: '',
        starTime: 0,
        // 题目信息 标题  题目数量
        questionnaireInfo: {
          questionnaireTitle: '',
          questionQuantity: 10,
        },
        questionIds: [],
        //题目及选项
        question: '',
        btnOption: [],
        // 进度条数据
        percentage: 0,
        customColors,
        toggleToFinished: false,
      }
    },
    methods: {
      async getQuestionnaireInfo() {
        const res = await questionnaireApi.getQuestionnaireInfo()
        // 测试已过期
        if (Date.now() > Token.getExpiration()) {
          this.$store.commit('questionnaire/REMOVE_TOKEN')
        } else {
          this.questionnaireInfo.questionnaireTitle = res.data.title
          this.questionnaireInfo.questionQuantity = res.data.questionIds.length
          this.questionIds = res.data.questionIds
          this.currentIndex = parseInt(Token.getCurrentIndex()) || 0
          this.getCurrentQuestion()
        }
      },
      //  点击选项后 直接下一题 提交下一题答案接口
      async handleNextQuestion(answerId) {
        if (!this.loading) {
          this.loading = true
          const time = Date.now() - this.starTime
          await questionnaireApi.submitAnwser(this.questionIds[this.currentIndex], answerId, {
            time
          })
          this.$refs.timer.resetTimer()
          this.nextQuestion()
        }
      },
      // 拿到每题的信息和选项  还有设置进度条
      async getCurrentQuestion() {
        const res = await questionnaireApi.getQuestion(this.questionIds[this.currentIndex])
        Token.setCurrentIndex(this.currentIndex)
        this.question = res.data.title
        this.btnOption = res.data.options
        this.percentage = parseInt(((this.currentIndex + 1) / this.questionnaireInfo.questionQuantity) * 100)
        this.starTime = Date.now()
        this.loading = false
      },
      // 下一个问题
      nextQuestion() {
        if (this.currentIndex === this.questionnaireInfo.questionQuantity - 1) {
          this.getResult()
          this.toggleToFinished = true
        } else {
          this.currentIndex++
          this.getCurrentQuestion()
        }
      },
      handleTimeOut() {
        this.$message({
          type: 'error',
          message: '你已超时，请尽快作答'
        })
      },
      async getResult() {
        this.$message({
          type: 'success',
          message: '做答完毕，结果已提交'
        })
        // this.$router.push('/result')
        await questionnaireApi.finishedSubmit('')
        this.$store.commit('questionnaire/REMOVE_TOKEN')
        this.loading = false
      },
      progress() {
        return `${this.currentIndex+1}/${this.questionnaireInfo.questionQuantity}`
      },
      backToHome(){
        this.$router.push('/')
      }
    },
    computed: {},
  }
</script>

<style lang="scss" scoped>
  .questContainer {
    position: relative;
    width: 100vw;
    height: 100vh;
    overflow: hidden;
  }

  .questionToggle {
    opacity: 0;
  }

  .finishedPage {
    position: absolute !important;
    max-width: 100vw;
    opacity: 0;
    z-index: -1;
    transition: all 0.8s ease;
    /*text-align: center;*/
    color: #212121;
    .finishedContentContainer {
       text-align: center;
       border: 1px solid #707070;
       max-width: 400px;
       width: 80%;
       margin: 50px auto 10vh;
       padding: 3px;
      .finishedInnerContaienr {
        border: 1px dashed rgba(0,0,0,0.2);
        padding: 20px 0;
        .finishedTitle {
          font-size: 30px;
          font-weight: bold;
          letter-spacing: 3px;
        }
        .finishedContent {
          line-height: 36px;
          margin: 30px 0;
        }
        .handshakeImg{
          width: 60%;
          object-fit: contain;
        }
      }

    }

    .fpImg {
      position: absolute;
      left: 50%;
      transform: translateX(-15vw);
      bottom: 10vh;
      width: 30vw;
    }
  }

  .finishedPageToggle {
    opacity: 1;
    z-index: 1;
  }

  .questionnaireTitle {
    text-align: center;
    font-size: 30px;
    font-weight: bold;
    margin: 4vh 0 3vh;
  }

  .questionContainer {
    position: relative;
    font-weight: bolder;
    font-size: 18px;
    margin: 10px auto;
    max-width: 400px;
    width: 100%;
    min-height: 130px;
    background-color: rgb(243, 245, 247);
    border-radius: 10px;
    padding-top: 50px;
    padding-bottom: 10px;

    .question {
      max-width: 70%;
      word-break: break-all;
      margin: 0 auto;
    }
  }

  .progress {
    position: absolute;
    right: 14px;
    bottom: 10px;
    font-size: 16px;
    font-weight: normal;
  }

  .circlePogress {
    position: absolute;
    right: 10px;
    margin-top: 20px;
  }

  .timer {
    position: absolute;
    left: 50%;
    transform: translateX(-50px);
    bottom: 10px;
    z-index: 999;
  }
</style>
