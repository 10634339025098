import axios from '../utils/api'

// /api/v1/
class questionnaireApi {
  static cheackInvitationCode (invitationCode) {
    return axios.post('/judge/psych/token', { invitationCode })
  }

  static getQuestion (questionId) {
    return axios.get(`/judge/psych/${questionId}`)
  }

  static submitAnwser (questionId, answerId, data) {
    return axios.post(`/judge/psych/${questionId}/${answerId}`, data)
  }

  static getQuestionnaireInfo () {
    return axios.get(`/judge/psych/`)
  }

  static finishedSubmit (remark) {
    return axios.post(`/judge/psych/submit?remark=${remark}`)
  }

  static timeRecord (times) {
    return axios.post('/sundry/time', {times})
  }
}

export default questionnaireApi
