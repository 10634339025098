<template>
  <div class="homeContainer">
    <div class="illustrationContainer">
      <div class="illustrationTitle">在线测评</div>
      <div class="illustrationSubTitle">欢迎您参与本次测评 ！</div>
      <div v-if="goToNext===0" class="illustration">
        <img class="homeBg" src="../assets/homeBg.png">
        <img class="boyImg" src="../assets/boy.png">
        <img class="girlImg" src="../assets/girl.png">
      </div>

      <div v-if="goToNext===1" class="guideInfoContainer">
        <div class="guideInfo">
          <div class="guideInfoTitle">测前须知</div>
          <div class="content" v-for="(item) in guideInfoGroup" :key="item">
            <div class="dot"></div>
            {{item}}
          </div>
          <img class="writeImg" src="../assets/writeImg.png" alt="测前须知">
        </div>
      </div>
      <div v-else-if="goToNext===2" class="guideInfoContainer">
        <div class="guideInfo">
          <div class="guideInfoTitle">注意事项</div>
          <div class="content" v-for="(item) in guideInfoGroup2" :key="item">
            <div class="dot"></div>
            {{item}}
          </div>
          <img class="writeImg" src="../assets/writeImg.png" alt="测前须知">
        </div>

      </div>
    </div>
    <div class="btnNext" @click="goNext">{{goToNext===2?'我已了解':goToNext===1?'下一步':'开始测评！'}}</div>
    <el-dialog title="提示" :visible.sync="centerDialogVisible" class="dialogContaienr" width="80%" center>
      <span class="dialogContent">请在网络状态良好且安静的环境下开始测评的填答，如遇干扰因素，请退出答题页面，并在合适的时间重新点击邮箱内的测评链接登入。</span>
      <span slot="footer" class="dialog-footer">
        <div class="dialogBtn" @click="next">准备好了，即刻开始。</div>
        <div class="dialogBtn" @click="centerDialogVisible = false" style="margin: 0">未准备好，稍后继续。</div>
      </span>
    </el-dialog>
  </div>
</template>

<script>
  import {
    btnOption
  } from '../utils/enum'
  import questionnaireApi from '../api/questionnaire-api'
  import {
    Message
  } from "element-ui";

  export default {
    name: 'Home',
    data() {
      return {
        centerDialogVisible: false,
        goToNext: 0,
        btnOption,
        guideInfoGroup: ['本测评题没有对错优劣之分', '请依据自己“真实”情况，而非“理想”情况作答', '每道题的作答时间为10秒', '本测评共有54道题'],
        guideInfoGroup2: [],
        starTime: 0,
        times: {
          next: 0,
          iKonw: 0
        },
        ruleFormData: {
          option: ''
        },
        // ruleForm: {
        invitationCode: ''
        // }
        // rules: {
        //   invitationCode: [{ required: true, message: '请输入邀请码', trigger: 'blur' }]
        // }
      }
    },
    mounted() {
      this.$route.query.code && (this.invitationCode = this.$route.query.code)
    },
    methods: {
      async next() {
        if (this.invitationCode) {
          const res = await this.$store.dispatch('questionnaire/checkInvitationCode', this.invitationCode)
          if (res) {
            this.centerDialogVisible = false
            const infoRes = await questionnaireApi.getQuestionnaireInfo()
            if (infoRes.data.guide) {
              const guides = JSON.parse(infoRes.data.guide)
              this.guideInfoGroup = guides.guide[0].split('\n')
              this.guideInfoGroup2 = guides.guide[1].split('\n')
            }
            this.starTime = Date.now()
            this.goToNext++
            return
          }
        }
        Message({
          type: 'error',
          message: '测评链接失效'
        })
      },
      async goNext() {
        if (this.goToNext === 0) {
          this.centerDialogVisible = true
          // this.$refs.ruleForm.validate(async (valid) => {
          //   if (valid) {
          //     // if (this.ruleForm.invitationCode === 'admin') {
          //     //   this.starTime = Date.now()
          //     //   this.goToNext++
          //     // }
          //     const res = await this.$store.dispatch('questionnaire/checkInvitationCode', this.ruleForm.invitationCode)
          //     if (res) {
          //       const infoRes = await questionnaireApi.getQuestionnaireInfo()
          //       console.log(infoRes.data)
          //       if (infoRes.data.guide) {
          //         const guides = JSON.parse(infoRes.data.guide)
          //         this.guideInfoGroup = guides.guide[0].split('\n')
          //         this.guideInfoGroup2 = guides.guide[1].split('\n')
          //       } else {
          //
          //       }
          //       this.starTime = Date.now()
          //       this.goToNext++
          //     }
          //   } else {
          //     return false
          //   }
          // })
        } else {
          if (this.goToNext === 2) {
            this.times.iKonw = Date.now() - this.starTime
            await questionnaireApi.timeRecord(this.times)
            this.$router.push({
              name: 'Questionnaire'
            })
          } else {
            this.times.next = Date.now() - this.starTime
            this.starTime = Date.now()
          }
          this.goToNext++
        }
      }
    }
  }
</script>
<style lang="scss" scoped>
  .homeContainer {
    width: 100vw;

    // 首页插画及标题
    .illustrationContainer {
      max-width: 440px;
      margin: 6vh auto 12vh;
      text-align: center;

      .illustrationTitle {
        font-size: 30px;
        margin-bottom: 23px;
      }

      .illustrationSubTitle {
        font-size: 16px;
      }

      .illustration {
        position: relative;
        margin-top: 60px;
        max-width: 440px;
        .homeBg {
          max-width: 440px;
          width: 80%;
        }

        .boyImg {
          position: absolute;
          right: 60px;
          max-width: 140px;
          width: 30%;
          transform: rotateY(180deg);
        }

        .girlImg {
          position: absolute;
          left: 60px;
          top: 50px;
          max-width: 140px;
          width: 30%;
        }
      }
    }

    // 弹框提示
    .dialogContaienr {
      position: absolute;
      top: -30%;
      max-width: 400px;
      width: 100%;
      margin: 0 auto;

      .dialogContent {
        font-weight: bolder;
        display: block;
        max-width: 240px;
        margin: 0 auto;
      }

      .dialogBtn {
        width: 100%;
        max-width: 240px;
        height: 38px;
        line-height: 38px;
        font-size: 14px;
        border: 2px solid #212121;
        border-radius: 12px;
        font-weight: bolder;

      }

      .dialogBtn:active {
        color: white;
        background-color: #212121;
      }

      .dialog-footer {
        display: flex;
        width: 100%;
        height: 120px;
        flex-direction: column;
        justify-content: space-around;
        align-items: center;
      }

    }
  }





  .guideInfoContainer {
    position: relative;
    text-align: left;
    border: 1px solid #707070;
    max-width: 400px;
    width: 80%;
    margin: 50px auto 0;
    padding: 3px;

    .guideInfo {
      border: 1px dashed rgba(0, 0, 0, 0.2);
      padding: 20px 14%;

      .guideInfoTitle {
        text-align: center;
        font-size: 18px;
        font-weight: bold;
        margin-bottom: 40px;
      }

      .content {
        /*text-indent: 1rem;*/
        position: relative;
        font-size: 14px;
        color: #212121;
        margin-bottom: 30px;
        padding-left: 16px;

        .dot {
          position: absolute;
          top: 6px;
          left: 0;
          width: 8px;
          height: 8px;
          border-radius: 100%;
          background-color: #212121;
        }
      }
    }

    .writeImg {
      position: absolute;
      right: -20px;
      bottom: -20px;
      width: 24%;
    }
  }

  .optionTitle {
    text-indent: 2em;
  }

  .introduction {
    .title {
      margin: 50px;
      text-align: center;
      font-size: 12px;
    }

    /*.content {*/
    /*  text-indent: 2em;*/
    /*  font-size: 14px;*/
    /*  !*margin-bottom: 50px;*!*/
    /*}*/
  }

  .showTime {
    margin: 50px;
    text-align: center;
  }
</style>
