import Vue from 'vue'
import VueRouter from 'vue-router'
import Home from '../views/Home.vue'
import Questionnaire from '../views/Questionnaire'
Vue.use(VueRouter)

const routes = [
  {
    path: '/',
    name: 'Home',
    component: Home,
    meta:{
      title:'title-test'
    }
  },
  {
    path: '/questionnaire',
    name: 'Questionnaire',
    component: Questionnaire
  }
  // ,
  // {
  //   path: '/result',
  //   name: 'Result',
  //   component: ()=>import('../views/Result')
  // }
]

const router = new VueRouter({
  mode: 'history',
  routes
})

export default router
