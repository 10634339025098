import CONFIG from '../config'

const { tokenKey,currentIndexKey,percentageIndexKey,expirationKey } = CONFIG
export default {
  // 对token的存储
  getToken () {
    return localStorage.getItem(tokenKey)
  },
  setToken (token) {
    return localStorage.setItem(tokenKey, token)
  },
  removeToken () {
    localStorage.removeItem(tokenKey)
  },
  // 存储过期时间
  getExpiration () {
    return localStorage.getItem(expirationKey)
  },
  setExpiration (expiration) {
    return localStorage.setItem(expirationKey, expiration)
  },
  removeExpiration() {
    localStorage.removeItem(expirationKey)
  },
  // 对请求问题索引的存储
  getCurrentIndex () {
    return localStorage.getItem(currentIndexKey)
  },
  setCurrentIndex (index) {
    return localStorage.setItem(currentIndexKey,index)
  },
  removeCurrentIndex(){
    return localStorage.removeItem(currentIndexKey)
  },
  // 对进度索引的存储
  getPercentageIndex () {
    return localStorage.getItem(percentageIndexKey)
  },
  setPercentageIndex (index) {
    return localStorage.setItem(percentageIndexKey,index)
  },
  removePercentageIndex(){
    return localStorage.removeItem(percentageIndexKey)
  }

}
