export const btnOption = [{
  info: '非常不符合',
  bgColor: 'background-color: rgb(253,239,239) !important'
}, {
  info: '有点不符合',
  bgColor: 'background-color: rgb(255,226,226) !important'
}, {
  info: '不确定',
  bgColor: 'background-color: rgb(255,208,208) !important'
}, {
  info: '有点符合',
  bgColor: 'background-color: rgb(255,200,200) !important'
}, {
  info: '非常符合',
  bgColor: 'background-color: rgb(253,176,176) !important'
}]
export const btnBgColor = ['background-color: rgb(253,239,239) !important', 'background-color: rgb(255,226,226) !important', 'background-color: rgb(255,208,208) !important', 'background-color: rgb(255,200,200) !important', 'background-color: rgb(253,176,176) !important']
export const customColors= [
  {color: '#f56c6c', percentage: 20},
  {color: '#e6a23c', percentage: 40},
  {color: '#5cb87a', percentage: 60},
  {color: '#1989fa', percentage: 80},
  {color: '#6f7ad3', percentage: 100}
]
