import questionnaireApi from '../../api/questionnaire-api'
import Token from '../../utils/auth'
import {Message} from 'element-ui'
import router from '../../router'
const state = {
  haveInvitationCode: false,
  // expiration:0
}
const mutations ={
  REMOVE_TOKEN(){
    Token.removeToken()
    Token.removeCurrentIndex()
    Token.removeExpiration()
  }
}
const actions = {
  async checkInvitationCode (content, code) {
    let res = await questionnaireApi.cheackInvitationCode(code)
    // res有内容才...
    if (!!res) {
      Token.setExpiration(res.data.expiration)
      // content.state.expiration=res.data.expiration
      Token.setToken(res.data.token)
    }
    return !!res
  },
  async getQuestionnaireInfo (content, id) {
    const res = await questionnaireApi.getQuestionnaireInfo(id)
    return res.data
  },
  async finishedSubmit (){
    // const res = await questionnaireApi.finishedSubmit()
    // Message({ type: 'success', message: '做答完毕' })
    // Token.removeToken()
    // Token.removeCurrentIndex()
    // Token.removePercentageIndex()
  }
}
export default {
  namespaced: true,
  state,
  actions,
  mutations
}
