<template>
  <div class="timerContaienr">
    <div>
      <img class="clock" src="../assets/clock.png" >
    <span class="time">{{getCountDown}}</span>
    </div>
  </div>
</template>
<script>
export default {
  name: 'timer',
  data () {
    return {
      minutes: 0,
      seconds: 10,
      timer: '',
    }
  },
  mounted () {
    this.countDown()
  },
  computed: {
    getCountDown(){
      let seconds = this.seconds>9?this.seconds:'0'+this.seconds
      return `0${this.minutes}:${seconds}`
    }
  },
  methods: {
    countDown () {
      this.timer = setInterval(() => {
        this.seconds--
        if (this.seconds === -1) {
          this.minutes--
          this.seconds = 59
        }
        if (this.minutes === -1) {
          this.seconds = this.minutes = 0
          this.clearTimer()
          this.$emit('timeOut')
        }
      }, 1000)
    },
    resetTimer() {
      this.minutes=0
      this.seconds=10
      this.clearTimer()
      this.countDown()
    },
    clearTimer() {
      clearInterval(this.timer)
    }
  }
}
</script>

<style lang="scss" scoped>
  .timerContaienr{
    font-size: 18px;
    font-weight: bold;
    color: #707070;
    .clock{
     transform: translateY(2px) translateX(-5px);
     width: 20px;
     height: 20px;
     margin-right: 2px;
    }
  }
</style>
