import router from '../router'
import store from '../store'
import Token from '../utils/auth'

const whiteList = ['/', '/404']
import NProgress from 'nprogress'
import 'nprogress/nprogress.css'

router.beforeEach(async (to, from, next) => {
  NProgress.start()
  if (Token.getToken()) {
    if (to.path !== '/questionnaire' && to.path !== '/result') {
      next('/questionnaire')
      NProgress.done()
    }
    next()
  }
  if (whiteList.indexOf(to.path) !== -1) {
    next()
  } else {
    // if (to.path === '/result') {
    //   next()
    // } else {
    //   next('/')
    // }
    next('/')
    NProgress.done()
  }
})
router.afterEach(() => {
  NProgress.done()
})
